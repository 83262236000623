import React, {Component} from 'React';
import TableOfContents from './TableOfContents';
import Sidebar from './Sidebar';
import MainContent from './MainContent';

class Layout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showTableOfContents: true,
            showSidebar: false
        };

            this.showSidebar = this.showSidebar.bind(this);
            this.renderSidebar = this.renderSidebar.bind(this);
            // this.handleScroll = this.handleScroll.bind(this);
    };

    // handleScroll(e) {
    //     console.log(window.scrollY);
    // }

    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll);
    // }

    showSidebar() {

        this.setState({
            showSidebar: !this.state.showSidebar
        });
        console.log('heyyy')
    }


    // showSidebar() {
    //     this.setState(state => ({
    //   showSidebar: !state.showSidebar
    //     }));
    //     console.log('heyyy')
    // }

    renderSidebar() {
        console.log(this.state.showSidebar);
        if (this.state.showSidebar) {
            return (
                <Sidebar />
            )
        }
    }

    render() {
        return (
            <div>
                <TableOfContents showSidebar={this.showSidebar}/>
                <MainContent />
                {this.renderSidebar()}
            </div>
        )
    }
};

export default Layout;