import React, {Component} from 'React';

class Sidebar extends Component {

    render() {
        return (
        <div id="mySidebar" class="sidebar"> 
            <div class="lyric-sidebar">
              <div class="lyrics">
                <h3 style={{fontSize:'25px', textAlign:'center', paddingBottom:'5%' }}>Lyrics</h3>
                <p style={{color:'red'}}><strong>[Intro]</strong></p>
                <p>Sha, la, la, la, la, la, la<br />Mmm<br />Uh huh</p>
                <p style={{color: 'red'}}><strong>[Verse]</strong></p>
                <p>I was down at the New Amsterdam<br />
                 Staring at this yellow-haired girl<br />
                 Mr Jones strikes up a conversation<br />
                 With a black-haired flamenco dancer<br />
                 You know, she dances while his
                 father plays guitar<br />
                 She's suddenly beautiful<br />
                 We all want something beautiful<br />
                 Man, I wish I was beautiful<br />
                 <br />
                 So come dance the silence down through the morning<br />
                 Sha la, la, la, la, la, la, la<br />
                 Yeah<br />
                 Uh huh<br />
                 Yeah<br />
                 Cut up, Maria!<br />
                 Show me some of that Spanish dancin'<br />
                 Pass me a bottle, Mr Jones<br />
                 Believe in me<br />
                 Help me believe in anything<br />
                 'Cause I want to be someone who believes<br />
                 Yeah<br /></p>
                 <br />
                <p style={{color: 'red'}}><strong>[Chorus]</strong></p>
                <p>Mr Jones and me<br />
                Tell each other fairy tales<br />
                And we stare at the beautiful women<br />
                She's looking at you<br />
                Ah, no, no, she's looking at me<br />
                Smilin' in the bright lights<br />
                  Coming through in stereo<br />
                  When everybody loves you<br />
                  You can never be lonely</p>
              <p>&nbsp;</p>
                <p style={{color: 'red'}}><strong>[Verse]</strong></p>
                <p>Well, I'm gonna paint my picture<br />
                Paint myself in blue and red and black and gray<br />
                All of the beautiful colors are very, very meaningful<br />
                Yeah, well, you know gray is my favorite color<br />
                I felt so symbolic yesterday<br />
                If I knew Picasso<br />
                I would buy myself a gray guitar and play</p>
                <br />
                <p style={{color: 'red'}}><strong>[Chorus]</strong></p>
                <p>Mr Jones and me<br />
                Look into the future<br />
                Yeah, we stare at the beautiful women<br />
                She's looking at you<br />
                I don't think so<br />
                She's looking at me<br />
                Standing in the spotlight<br />
                I bought myself a gray guitar<br />
                When everybody loves me<br />
                I will never be lonely
                </p>
                <p>&nbsp;</p>
                <p style={{color: 'red'}}><strong>[Bridge]</strong></p>
                <p>I will never be lonely<br />
                Said I'm never gonna be<br />
                Lonely<br />
                I wanna be a lion<br />
                Yeah, everybody wants to pass as cats <br />
                We all wanna be big, big stars<br />
                Yeah, but we got different reasons for that<br />
                Believe in me<br />
                'Cause I don't believe in anything<br />
                And I wanna be someone to believe, to believe, to believe<br />
                Yeah!</p>
                <br />
                <br />
                <p style={{color: 'red'}}><strong>[Chorus]</strong></p>
                <p>Mr Jones and me<br />
                Stumbling through the Barrio<br />
                Yeah, we stare at the beautiful women<br />
                She's perfect for you<br />
                Man, there's got to be somebody for me<br />
                I wanna be Bob Dylan<br />
                Mr Jones wishes he was someone just a little more funky<br />
                When everybody love you<br />
                Oh! Son, that's just about as funky as you can be<br />
                Mr Jones and me<br />
                Starin' at the video<br />
                When I look at the television, I wanna see me<br />
                Staring right back at me<br />
                We all wanna be big stars<br />
                But we don't know why, and we don't know how<br />
                But when everybody loves me<br />
                I'm wanna be just about as happy as I can be<br />
                Mr Jones and me<br />
                We're gonna be big stars</p>
              </div>
            </div>
          </div>
        );
    }
};

export default Sidebar;