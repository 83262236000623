import React, {Component} from 'React';
import Container from "./container"
class MainContent extends Component {

  render() {
    return (
      <div>
       <Container>
              <p><em><span style={{color: '#ff5656' }}>Mr. Jones</span></em> is a song by the band the Counting Crows. It was a mega-hit in the 90's and would go on to become an anthem of the decade. After it was released, it spent 47 weeks on the Billboard top 100 charts, peaking at #5. The song is still played heavily to this day, according to the numbers below:</p>         
              <p>(# of plays)</p>
              <p>Spotify: 228,770,515</p>
              <p>YouTube:&nbsp;<span>128,136,102</span></p>         
              <p>But there's something more going on here. Together we will explore the dark labyrinths hidden within the song, as well as the storied histories of the band and the lead singer <span style={{color: '#ff0201' }}>Adam Duritz</span>. By the end of reading this, you will hopefully understand the various occultist ties, hidden meanings, and dark incantations within the song. Only then will you see that this tune was pulled from an ancient morass of depravity, one only accessed by acolytes of a darker power.</p>         
              <p>If you haven't heard the song before, I highly recommend listening to it before continuing so that you can follow along:&nbsp;</p>
              <div class="imgContainer"><iframe class="youtube" title="Mr. Jones Music Video" xml="lang" src="//www.youtube.com/embed/VuHVZ_-b868" height="314" allowfullscreen="allowfullscreen" data-service="youtube"></iframe></div>        
              <p>I myself have listened to the song hundreds of times. Possibly a thousand times. And there's no telling how many times the song has shown up in my dreams,&nbsp;all the haunting melodies and vivid imageries manifesting in my mortal mind's eye.&nbsp;&nbsp;</p>         
              <p>Shall we start from the beginning?</p>
          </Container>
          <Container>
            <div class="container" id="origins">
              <h2>Origins</h2>
              <div class="imgContainer"><img class="img" src="https://cdn2.hubspot.net/hubfs/2272014/countingcrowsjpg-486d44be11f9691e_large.jpg" alt="countingcrowsjpg" style={{ width: '432px' }}></img></div>  
              <p>The Counting Crows formed in 1991 in Seattle, WA, starting with only the singer <span style={{color: '#ff5656'}}>Adam Duritz</span> and guitarist David Bryson. The band's name has its esoteric origins in an old British divination nursery rhyme named <em>One for Sorrow</em>. The nursery rhyme is&nbsp;about the superstitious act of counting magpies, a bird in the crow family that is often associated with bad omens. This is a clever play on words and the first of many dark references to be hidden in plain sight. Below is the ancient rhyme:</p>
              <div class="centerQuote"><br />One for sorrow<br />Two for joy<br />Three for a girl<br />Four for a boy<br />Five for silver<br />Six for gold<br />Seven for a secret<br />Never to be told<br />Eight for a wish<br />Nine for a kiss<br />Ten for a bird<br />You must not miss</div>
              <p></p>
              <p>This rhyme would appear in one of the band's earlier tracks, "<a href="https://www.youtube.com/watch?v=5ePfsdr94ow" target="_blank" rel="noopener noreferrer">A Murder of One</a>." The first record of this nursery rhyme is from 1780, and its power was known even back then. Some say this incantation was responsible for the event on May 19th of the same year, referred to as <a href="https://en.wikipedia.org/wiki/New_England%27s_Dark_Day" rel="noopener noreferrer" target="_blank">New England's Dark Day</a>. On this day, an unusual darkness spread over the New England states and parts of Canada. It is said that the darkness was so complete that candles were required from as early as noon, and the darkness did not disperse until the next day. <a href="https://books.google.com/books?id=5cFGAQAAIAAJ&amp;pg=PA10#v=onepage&amp;q&amp;f=false" rel="noopener noreferrer" target="_blank">Locals reported</a> an atmosphere of 'unusual nature' in areas where the darkness spread.&nbsp;In the days leading up to the event, the sun was <a href="http://www.islandnet.com/~see/weather/almanac/arc2004/alm04may.htm" rel="noopener noreferrer" target="_blank">said to be red</a>, and the sky yellow. Could this darkness have been a result of something more sinister? One can imagine a sleepy New England village, air thick with witchcraft as an ancient power was summoned from the very earth, blacking out the sky for more than a day. With this power now pervading the land, what dark rituals could be cast within those shadowed New England woods?</p>
              <p>And here we arrive at the reason for the song's power: the band relies on olde magick to influence their listeners. And at the center of this band, our singer <span style={{color: '#ff5656'}}>Adam</span> leads the incantation.</p>
            </div>
          </Container>
          <Container>
            <div  class="container" id="adam">
              <h2><span style={{color: '#ff5656'}}>Adam</span></h2>
              <p><span style={{color: '#ff5656'}}>Adam</span> attended the legendary <a href="https://en.wikipedia.org/wiki/Taft_School" rel=" noreferrer noopener" target="_blank">Taft School</a>, a private school&nbsp;founded by President Taft's brother, <a href="https://en.wikipedia.org/wiki/Horace_Dutton_Taft" rel=" noopener noreferrer" target="_blank">Horace Dufton Taft</a>, in 1890.</p>
              <div class="imgContainer"><img style={{width: '155px'}} src="https://cdn2.hubspot.net/hubfs/2272014/mjt/taftzoom.png" alt="taftzoom"></img></div>
              <p class="subtitle">A statue of the school's founder, weeping dark tears.</p>
              <p></p>
              <p>The school is for grades 9-12 and has produced a vast network of <a href="https://en.wikipedia.org/wiki/List_of_Taft_School_alumni" rel=" noopener noreferrer" target="_blank">prominent alumni</a>. This illuminati network would serve to be <span style={{color: '#ff5656'}}>Adam</span>'s entrance to untold power.</p>
              <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/taft2.jpg" alt="Taft2"style={{width: '300px'}} /></div>
              <p></p>
              <p>From the Taft School Honor Code:</p>
              <p style={{padding: '0% 5%'}}><em>The Honor Code is both an anchor and a compass, grounding and guiding Taft students. And it is continually reinforced: Every assessment—whether daily homework or a final exam—ends with the student's written pledge: "I have neither given nor received aid on this paper." It a simple but important commitment: Honesty and integrity matter, and&nbsp;your word is your bond.</em></p>
              <p>By committing his students to pledges like these, Taft made his students sign away their souls, ensuring that those who walked the hallowed halls would be&nbsp;tied to the energy of the campus, and in turn would give their life force to Taft's maleficent practices. This private school would also serve as a primer for&nbsp;a secret society; students who pledged loyalty to Taft would be allowed into the society as junior members, and thus given the opportunity to move up in the ranks.&nbsp;</p>       
              <p>Which secret society, you ask? Not only was&nbsp;Howard Dutton Taft himself a prominent member of the <a href="https://en.wikipedia.org/wiki/Skull_and_Bones" rel="noopener noreferrer" target="_blank">Skull and Bones society</a>, his father Alphonso Taft was the very founder.&nbsp;When&nbsp;Howard Dutton Taft&nbsp;joined the cult's ranks in 1878, he was the <a href="https://www.buergerwelle.de/assets/files/skull_and_bones_member_list_to_1983.html" rel="noopener noreferrer" target="_blank">fourth Taft </a>to join, but more importantly he was the heir to a shadowed throne.&nbsp;</p>        
              <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/-conspiracy-theory-politics--skull-and-bones-member-list-to-1983.png" alt="-conspiracy-theory-politics--skull-and-bones-member-list-to-1983" style={{width: '580px' }}></img></div>
              <p>With all of this known about the Taft school, what implications are there about <span style={{color: '#ff0201'}}>Adam</span>?&nbsp;One can only speculate that his connections run deep, and brings into question how powerful he truly is.&nbsp;And more importantly, one has to wonder:<em>Who is the living heir?</em></p>
              <p>There is a even direct reference to the secret society in the song itself. We will&nbsp;analyze the lyrics in full further down, but for now we only need to look as far as the length of the song to understand: 4:32. If you've seen the Skull and Bones society emblem, that number should look familiar:</p>
              <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/220px-Bones_logo.jpg" alt="220px-Bones_logo" style={{width: '220px'}}></img></div>
              <p></p>
              <p>322 consists of the same numerology as the song's length. The 3, the 2, and the 4 created by adding the&nbsp;2s together. It's a subtle reference, but&nbsp;should be apparent to anyone who keeps their eye out for coded symbols.</p>
              <p>What does this connection imply? 322 is said to have many significant meanings to the Skull and Bones, but most notably it is the year of&nbsp;Greek orator&nbsp;<a href="https://en.wikipedia.org/wiki/Demosthenes" title="Demosthenes">Demosthenes</a>' death. It is said that members of the society will&nbsp;measure dates by this year instead of common era. If we measure 432 years after the year 322, we arrive at the year 754. In that year on July 28, <a href="https://en.wikipedia.org/wiki/Pope_Stephen_II">Pope Stephen II</a> re-consecrated Pepin III (the Short) as king of the Franks.&nbsp;The pope&nbsp;bestowed upon him the additional title of&nbsp;<a href="https://en.wikipedia.org/wiki/Patrician_(ancient_Rome)#Late_Roman_and_Byzantine_periods" title="Patrician (ancient Rome)">Patricius of the Romans</a>. This marks the first recorded&nbsp;crowning&nbsp;of a civil ruler by a&nbsp;pope.</p>
              <br />
              <p>This reference is a&nbsp;direct message from&nbsp;<span style={{color: '#ff0201'}}>Adam</span>&nbsp;saying that he has been crowned by the Divine Powers That Be — not once, but twice. This song serves as his coronation, a divine rite that all who listen bear witness to. When his first coronation was or what the ceremony looked like, we can only guess.</p>
              <p></p>
              <p>A final note on President&nbsp;Taft:</p>     
              <p>In addition to the Skull and Bones society, there is much evidence that ties President Taft to the occult. One of the bolder claims is that Taft himself was a practicing witch. This makes sense considering the family's deep New England roots and the numerous visits to esoteric landmarks.</p>
              <p>The President visited Salem on three recorded occasions (though there&nbsp;are likely&nbsp;many more we're unaware of).&nbsp;What exactly he was doing in Salem during&nbsp;his visits is debated, though we do have this photo:</p>            
              <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/taft_in_salem.jpg" alt="taft_in_salem" style={{width: '534px'}}></img></div>
              <p>This photograph has yet to be solved. He wields what appears to be a baton, as if conducting&nbsp;an unseen orchestra. No one else in the photo seems to be aware of this music — could it be that only Taft himself can hear the haunted tones?</p>            
              <p>Whatever the truth, questions remain: Why were all of&nbsp;these children gathered? Where are the teachers? And why did Taft never visit again after this photograph was taken?</p>
              <p> This photograph will prove to be of importance later on. </p>
          </div>
        </Container>
        <Container>
          <div class="container" id="lyrics">
          <h2>Lyrics</h2>
          <div>In the next section, we'll be looking at the lyrics of the song. Click "see lyrics" in the left sidebar for reference (lyrics will not display on mobile).&nbsp;</div>
          <p>The song starts off with <span style={{color: '#ff0201'}}>Adam</span>'s voice alone with a guitar and a shaking tambourine. His voice bleats out into the void in&nbsp;a&nbsp;most haunting manner, and one can't help but imagine this as the opening lines of a dark ritual. We the listeners are being primed for the spell that is to come.&nbsp;</p>
          <p>We begin at a location identified as "the New Amsterdam," both a play on "New England" (see: witchcraft) and a reference to <span style={{color: '#ff0201'}}>Adam </span>himself, as Amsterdam can be shorted to A'dam (thus signaling <span style={{color: '#ff0201'}}>Adam</span>'s rebirth into the form of&nbsp;New A'dam).&nbsp;<span style={{color: '#ff0201'}}>Adam</span> is staring at a yellow-haired girl, which signals the first reference to the Salem Witch Trials. It was believed that there were dark spirits or devils who took the form of animals marked by unique characteristics (known as 'familiars'). Among these animals is a <a href="https://news.nationalgeographic.com/2017/05/salem-witch-hunt-trump-tweet/" rel=" noopener noreferrer" target="_blank">yellow bird</a>.</p>
          <p>Next, we meet <span style={{color: '#ff0201'}}>Mr. Jones</span> himself. In the beginning, of the song he is speaking with a "black-haired flamenco dancer." Could this&nbsp;second woman be yet another devil's familiar, this time the Black Dog?</p>
          <p>What is also striking about this scene is that this black-haired woman "dances while his father plays guitar." Whose father? It seems to indicate that it's <span style={{color: '#ff0201'}}>Mr. Jones</span>'s father that is playing guitar.<span style={{color: '#ff0201'}}> Adam</span> and <span style={{color: '#ff0201'}}>Mr. Jones</span>&nbsp;stare at the women, watching them dance, and are filled with a sense of love and a belief that they will never be lonely as long as they pledge their souls to the devil and his dark music. If <span style={{color: '#ff0201'}}>Mr. Jones</span>'s father is the conductor, then one must assume <span style={{color: '#ff0201'}}>Mr. Jones</span> possesses dark powers himself. I explore the <a href="#theology" class="">theological</a> implications of <span style={{color: '#ff0201'}}>Mr. Jones</span> and the Father further down, but for now let's assume <span style={{color: '#ff0201'}}>Mr. Jones</span> is an avatar for Satan while the Father is God.</p>
          <p>The actual relationship between <span style={{color: '#ff0201'}}>Adam</span> and <span style={{color: '#ff0201'}}>Mr. Jones</span> is not clear, but we do know a few things.&nbsp;</p>
          <p>When <span style={{color: '#ff0201'}}>Adam</span> sings,"Pass me a bottle, Mr. Jones," we begin to unravel their relationship.&nbsp;There are two discernible meanings of the Bottle symbol, the first of which will be covered here (see below for other theories). This more common interpretation is that they are drinking alcohol. One can imagine the two of them passing a bottle back and forth, and by sharing a drinking vessel, <span style={{color: '#ff0201'}}>Adam</span> begins&nbsp;his communion&nbsp;with the devil and sin.&nbsp;</p>
          <p><span style={{color: '#ff0201'}}>Adam</span>'s pledge continues: "Help me believe in anything / Cause I want to be someone who believes." His&nbsp;desperation is clear—he has no guiding force in his life. Whether this is a result of his own circumstances or is in fact the work of the Devil, draining <span style={{color: '#ff0201'}}>Adam</span> of his life force, he has reached the place where his soul is ready for devotion to the dark throne.</p>
          <p>But there is another figure in this song. Though she is mentioned only once, her appearance resonates throughout the entirety of the track and the even the whole album: Maria.</p>
          <h3><strong>Maria</strong></h3>
          <p>As we're introduced to Mr. Jones's world, we meet a fabled "Maria." It isn't clear if this is the name of the Spanish Dancer, or if this is yet another anonymous woman in <span style={{color: '#ff0201'}}>Adam</span>'s psyche?&nbsp;</p>
          <p>"Cut up Maria, show me one of them Spanish dances," he says, and as she twirls and spins to the music, our transformation begins. And this is not the only time we see Maria in a Counting Crows song. She appears in many of their songs. In their second single <em>Round here</em>, Adam croons, "Maria says she's dying, through the front door I see her crying," and "Maria came from Nashville with a suitcase in her hand." In another song,&nbsp;&nbsp;<em>Mrs. Potter's Lullabye</em>,&nbsp;Adam offers that, "There's a piece of Maria in every song that I sing." She is also mentioned on the background writing on the cover of "August And Everything After"<strong> (<a href="#cover">below</a>)</strong>. The identity of Maria has never been revealed. To many, this proves that she is not a real person, but a symbol for loneliness, wanting, or something else.&nbsp;Perhaps she is one of those symbols. Perhaps she is many. Perhaps she is none of them.&nbsp;</p>
          <p>The plot thickens in the song as we learn of another member of this twisted ceremony...</p>
          <h3>Jakob&nbsp;</h3>
          <p>We learn of Adam's connection to Jakob Dylan (Bob Dylan's son) through the line, "I wanna be Bob Dylan." In an <a href="https://www.usatoday.com/story/popcandy/2013/07/03/adam-duritz/2486313/" rel=" noopener noreferrer" target="_blank">interview</a>, <span style={{color: '#ff0201'}}>Adam</span> stated that he met Jakob while working at an establishment called the <a href="https://en.wikipedia.org/wiki/The_Viper_Room" rel=" noopener noreferrer" target="_blank">Viper Room</a> in the mid-nineties. This night club&nbsp;is a hangout for the Hollywood elite,&nbsp;and <span style={{color: '#ff0201'}}>Adam</span> found himself&nbsp;working at the bar there shortly after he&nbsp;gained his fame.</p>
          <p>But there is a darker side to the Viper Room. It is rumored that it&nbsp;was a location for&nbsp;hundreds of secret Hollywood drug-fueled get-togethers. Not only that, but it was the location for an <a href="https://www.vanityfair.com/style/society/2014/07/viper-room-hollywood-poker-game" rel=" noopener noreferrer" target="_blank">illegal gambling ring</a>&nbsp;where high-rolling celebrities went to bet millions. Despite its clout, there are very few photos of celebrities going into or coming out of the club, which should raise a red flag (who was paying to keep those photos out of the media?) More recently in 2002, Anthony Fox, who co-owned the establishment with Johnny Depp, <a href="http://charleyproject.org/case/anthony-vivien-fox" rel=" noopener noreferrer" target="_blank">went missing</a>&nbsp;just before he was set to testify against Depp for fraud.&nbsp;Fox hasn't been seen since.</p>
          <p>But what does this have to do with the Dylans? Ask yourself:&nbsp;<a href="https://www.cia.gov/library/abbottabad-compound/FC/FC2F5371043C48FDD95AEDE7B8A49624_Springmeier.-.Bloodlines.of.the.Illuminati.R.pdf" rel=" noopener noreferrer" target="_blank">Who</a> are the fifty-five families?</p>
          <p>To&nbsp;dive even deeper into the lyrics, we need to listen to the song without any instrumentation. Here is the vocals-only version of the song:<br /><br /></p>
          <div class="youtube"><iframe title="mrjones3" xml="lang" src="//www.youtube.com/embed/uoIws8TH4yk" allowfullscreen="allowfullscreen" data-service="youtube"></iframe></div>
          <br />[! UPDATE: this video has been removed from YouTube. Coincidence? !]<br />
          <p>As you can hear,&nbsp;there are many more mouth and breathing sounds present in this song once the vocals are isolated. I believe this is a practice referred to as "breath chanting" where a spell can be cast from the energy of each breath between words.</p>
          <p>The chorus harmonies which are normally hidden in the mix stand out, like a choir of the damned mumbling along in unison. If you listen carefully, you'll also notice that the harmonies are provided by women—perhaps the yellowed-hair and black-haired women? Even after only a couple listens, I know I will be hearing those haunting&nbsp;<em>Hey la la'</em>s in my nightmares for years to come. This is the track that really opened my eyes and ears to the hidden meanings in this song. If you're skeptical, I urge you to give it another listen.</p>
          <p>There are several themes that are prevalent throughout the song's lyrics, but one of the more important is the theme of <span style={{textDecoration: 'underline'}}>transformation</span>.</p>
          <p>In the first verse, we observe that, as the black-haired flamenco dancer dances, she becomes "suddenly beautiful." Ritual magic is responsible for this transformation, changing the physical form of this demon right before our very eyes. Her form goes from that of a crone to an irresistible temptress.</p>
          <p><span style={{color: '#ff0201'}}>Adam</span>'s own transformation can be seen in the song as well. In the first verse of the song, he declares "I wish I was beautiful." This declaration immediately follows the transformation of the woman—now that he's witnessed the devil's power firsthand, he is intrigued. At this stage, he desires to be transformed at a physical level, a minor change to his appearance to sate his vanity. This sin of vanity signals to the devil that <span style={{color: '#ff0201'}}>Adam</span> is on his side. Later in the bridge of the song, <span style={{color: '#ff0201'}}>Adam</span> declares, "I wanna be a lion." Not just wanting to be beautiful anymore, <span style={{color: '#ff0201'}}>Adam</span> is expressing his desires to transform into a wild familiar, to join the devil's symbolic ranks like the yellow-haired girl as the Yellow Bird and the dancer as the Black Dog. This is echoed in the haunting line, "I felt so symbolic yesterday."</p>
          <p>This progression from Vanity to Pledge illustrates a meta-transformation. Indeed, as&nbsp;<span style={{color: '#ff0201'}}>Adam</span> brings us deeper into the music, we ourselves are being tempted to want more, to desire other forms, to leave our mortal souls behind and join the devil's ranks.</p>
        </div>
      </Container>
      <Container>
        <div class="container" id="cryptography">
        <h2><strong>Cryptography</strong></h2>
         <p>Earlier, we looked at the Bottle as a symbol of ritual magick and communion. For a long time, this&nbsp;was the most widely accepted explanation pulled out of the nightmarish nonsense. While there may be much truth to that interpretation, many felt that it didn't fully explain the situation. Despite further investigation, the path seemed to end there. There were no new clues, and&nbsp;all threads ended back where they started.</p>
         <p>But&nbsp;recently the CIA declassified a <a href="https://www.cia.gov/library/readingroom/collection/cia-declassifies-oldest-documents-us-government-collection" rel=" noopener noreferrer" target="_blank">set of secret writing documents</a>, in which we find <a href="https://www.cia.gov/library/readingroom/docs/Secret-writing-document-six.pdf" rel=" noopener noreferrer" target="_blank">Document Six</a>.&nbsp;</p>
         <p>The set of documents focuses on the practice of&nbsp;secret&nbsp;writing by using different liquid and revealing methods to encrypt messages sent across enemy lines. This was, and still is, an extremely popular way to send vital information across the world. As with all innovative consumer technology, the techniques were first developed and honed by the military. However, the practice of secret writing was quickly adopted by many secret societies so that communication could be more free and explicit.&nbsp;</p>
         <p></p>
         <p>The&nbsp;Bottle therefor is a direct reference to <span style={{color: '#ff0201'}}>Adam</span>'s preferred method of secret writing: ink. In Document Six, the CIA acknowledges the selling of iridium ink in large bottles to businesses such as laundries in order to secretly transport it (talk about laundering). “This ink,” we learn from Document Six, “is used for marking linen and...is often used for smuggling information across the frontier, when the writing is put on handkerchiefs, shirts, underwear, or on paper surfaces.” This very well may be a reference to <span style={{color: '#ff0201'}}></span>Adam writing coded messages on his own clothing so that he can transmit secret messages&nbsp;in plain sight. If he intends to sport his coded messages on the very clothing he wears in front of us, then he is clearly brazen and foolish. And yet, perhaps this is in an attempt to flaunt, to say that we could never decipher his coded messages even if they were in front of our eyes. More importantly, who are the messages for?</p>
         <p><span style={{color: '#ff0201'}}>Adam</span>'s clothing isn't the only thing covered in secretive ink—we need only turn to the album's cover to find further cryptographic phenomena.</p>
        <p style={{textAlign: 'center'}}>&nbsp;</p>
        <div class="imgContainer" style={{textAlign: 'center'}}>Front:
          <br />
          <br />
            <img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/albumfront.jpg" alt="albumfront" style={{width: '60%'}} id="cover"></img>
          </div>
        <p style={{textAlign: 'center'}}>Back:&nbsp;</p>
        <div class="imgContainer" style={{textAlign: 'center'}}><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/album%20back.jpg" alt="album back" style={{width: '60%'}}></img></div>
        <p>The second thing that we learn from Document Six is an additional property of these inks: color.&nbsp;That is, the color of a revealed invisible ink will be different depending on its ingredients:</p>
        <ul>
        <li>Starch on linen will become <span style={{textDecoration: 'underline'}}>blue</span> when fumed with iodine or potassium iodide.</li>
        <li>Antimony will develop <span style={{textDecoration: 'underline'}}>red</span> writing when fumed with hydrogen sulphide vapor.</li>
        <li>Iridium will develop <span style={{textDecoration: 'underline'}}>black</span> when fumed with mercurial vapor.</li>
        </ul>
         <p>Do those colors sound familiar? If not, let's remember <span style={{color: '#ff0201'}}>Adam</span>'s incantation in the first verse:</p>
         <div class="centerQuote"><p>Paint myself in blue and red and black and grey / </p>
         <p>All of the beautiful colors are very, very meaningful</p></div>
         <p>But notice that <span style={{color: '#ff0201'}}>Adam</span> refers to these inks in their colored form, as if they’ve already been revealed. <span style={{color: '#ff0201'}}>Adam</span>’s message, have been hidden for millennia, is now ready to be revealed to the masses. One of the properties of some secret inks is that they are revealed under light. As <span style={{color: '#ff0201'}}>Adam</span> notes, he is “smiling in the bright lights, coming through in stereo.” This is disturbingly self-aware and reveals how truly sinister this song is.</p>
        <p style={{fontSize:'24px'}}><strong>Numerology</strong></p>
        <p>Through&nbsp;numerology, <span style={{color: '#ff0201'}}>Adam</span> shows us hidden meaning through the lyrics of the song.&nbsp;We've already seen the significance of the song's length — 4:32 — in connection with the Skull and Bones society, but if we superimpose those numbers on top of the song, we can also derive additional hidden meanings.&nbsp;</p>
        <p>Beginning around World War I, <a href="https://en.wikipedia.org/wiki/Numbers_station" rel=" noopener noreferrer" target="_blank">numbers stations </a>have been used to transfer coded messages across open shortwave radio frequencies.&nbsp;If you are unfamiliar, take a listen to the one below:<br /><br /></p>
        <div class="youtube"><iframe title="mrjones4" xml="lang" src="//www.youtube.com/embed/QnXPqUU6fI0" allowfullscreen="allowfullscreen" data-service="youtube"></iframe></div>
        <p>If we use a numbers station as a sort of cypher by playing it over this song, what we find is astounding. Listen for the lyrics that are&nbsp;sang&nbsp;as you hear the numbers 4, 3, and 2.&nbsp;</p>
        <div class="soundcloud"><iframe title="mrjones5" allow="autoplay" xml="lang" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/563234952&amp;color=%23040404&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true"></iframe></div>
        <p>I will let you discover these code phrases yourself, but&nbsp;I would like to call attention to some of the word combinations that we find.</p>
        <p>First, we can pick out a reference to the <span style={{color: '#ffd966'}}>yellow father</span>. This is likely a reference to the <a href="https://en.wikipedia.org/wiki/The_King_in_Yellow" rel=" noopener noreferrer" target="_blank">King in Yellow</a>, a&nbsp;malevolent supernatural entity&nbsp; written about most famously by Robert W. Chambers. The <span style={{color: '#ffd966'}}>King in Yellow</span> is an ancient power, one mostly forgotten yet responsible for so much evil and despair in this world. We even find many references to this&nbsp;entity in the Cthulhu mythos and other esoteric works. What <span style={{color: '#ff0201'}}>Adam</span> seems to be doing here is either attempting to summon the <span style={{color: '#ffd966'}}>King in Yellow</span> himself or acknowledge his existing presence in our world. Later in the song, there is another coded message of "paint, paint you" which might also suggest that he is painting the listener&nbsp; in yellow as an offering to the <span style={{color: '#ffd966'}}>King</span>. If we look at this song as a dark ritual, the message becomes more clear and chilling.</p>
        <p>Another coded message we can pick up is "symbolic future." <span style={{color: '#ff0201'}}>Adam</span> might be suggesting that, should his ritual come to completion, we will be brought from this realm into another realm where there is no language save for the language of suffering. In this realm, the only communication is through coded symbols unintelligible to all but acolytes of the <span style={{color: '#ffd966'}}>King</span>. Once we enter this realm, we exist in all possible timelines: past, present, and future, with no possible escape. In the Skull and Bones society, there was also a rank of "Sherrife, Prince of Future." While records show that <span style={{color: '#ff0201'}}>Adam</span> did not go to Yale, could it be that he holds this title, or held it at one point?</p>
      </div>
      </Container>
      <Container>
        <div class="container" id="video">
        <h2>The music video</h2>
         <p>In the music video for this song, we follow <span style={{color: '#ff0201'}}>Adam</span> and his troupe as they descend into madness and evil, from dark alleyways to an anonymous club, from a desolate apartment into a twisting maze of urban landscape. Watch it below before proceeding:</p>
        <div class="youtube"><iframe title="mrjones6" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" xml="lang" src="//www.youtube.com/embed/-oqAU5VxFWs" frameborder="0" allowfullscreen="allowfullscreen"></iframe></div>
         <p>The first thing that you&nbsp;probably notice in the video is <span style={{color: '#ff0201'}}>Adam</span>'s brown jacket.&nbsp;However, what you might not notice at first is that there&nbsp;are&nbsp;<em>two</em> brown jackets that he wears throughout the song, signifying his duality. Mortal and immortal. Good and evil. Damned and risen.</p>   
         <p>Throughout the video, we can observe <span style={{color: '#ff0201'}}>Adam</span> dancing spastically&nbsp;as if possessed or reeling with unholy energy. It is disturbing to watch but important for understanding the level of madness that this man is going through. While he writhes, his brown, tasseled jacket is constantly on the precipice of falling off his person. Perhaps this is a symbol for <span style={{color: '#ff0201'}}>Adam</span> attempting to shed his mortal skin; while it sometimes seems to slide off his shoulders with ease, other times he pulls it tighter. It is a violent tug of war, both mentally and spiritually, physically and metaphysically.</p>
         <p>In the chorus, we clearly see one of the musicians playing organ. However, the organ is hardly audible. This ‘missing instrument’ is clearly being played for an audience, but we are not the audience—for there is a darker, singular audience for whom <span style={{color: '#ff0201'}}>Adam</span> and his haunted band play: <span style={{color: '#ff0201'}}>Mr. Jones</span>. This is either another reference to an unseen orchestra of the damned, as observed in the photograph of President Taft earlier, or is a much more gruesome representation of the ‘organs’ that they are providing <span style={{color: '#ff0201'}}>Mr. Jones</span>.</p>
         <p><span style={{color: '#ff0201'}}>Mr. Jones</span> himself makes an appearance 6 times in the music video and only confirms his malevolence.</p>
         <p>1) The first appearance isn’t until the <a href="https://youtu.be/-oqAU5VxFWs?t=139" rel=" noopener noreferrer" target="_blank">2:19</a> mark:</p>
         <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/mr%20jones1.png" alt="mr jones1" style={{width: '100%', maxWidth: '100%'}}></img></div>
         <p>He's watching a very small static television up on a lonely shelf in a shadowy room. He watches intently, as if he is able to discern messages through the static (further coded messages sent by <span style={{color: '#ff0201'}}>Adam</span>?)</p>
         <p>2) At the <a href="https://youtu.be/-oqAU5VxFWs?t=164" rel=" noopener noreferrer" target="_blank">2:44</a> mark, we see the figure of <span style={{color: '#ff0201'}}>Mr. Jones</span> shrink back into the shadow where he struts jauntily:</p>
         <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/mrjones2.png" alt="mrjones2" style={{width: '100%'}}></img></div>
         <p>This is the shadowed land where <span style={{color: '#ff0201'}}>Mr. Jones</span> lives, and <span style={{color: '#ff0201'}}>Adam</span> has brought us down to this level.</p>
         <p>3) <a href="https://youtu.be/-oqAU5VxFWs?t=175" rel=" noopener noreferrer" target="_blank">2:55</a> he is seen slinking through the alleyway:</p>
         <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/mrjonesagain.png" alt="mrjonesagain" style={{width: '100%'}} /></div>
         <p>4) <a href="https://youtu.be/-oqAU5VxFWs?t=222" rel=" noopener noreferrer" target="_blank">3:44</a> dancing now in the dark, bobbing his head, snapping his fingers to the rhythm in silhouette:</p>
         <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/mrjones3.png" alt="mrjones3" style={{width: '100%'}} /></div>
         <p>5) <a href="https://youtu.be/-oqAU5VxFWs?t=236" rel=" noopener noreferrer" target="_blank">3:56</a> <span style={{color: '#ff0201'}}>Mr. Jones</span> is seen yet again watching his static TV:</p>
        <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/mrjones4.png" alt="mrjones4" style={{width: '100%'}} /></div>
         <p>This is the same shot that we see from 2:19, and perhaps symbolizes a return to coded messages. <span style={{color: '#ff0201'}}>Mr. Jones</span> is still receiving <span style={{color: '#ff0201'}}>Adam</span>’s incoming communication and considering his proposal: his mortal soul for fame, fortune, and power.</p>
         <p>6) The sixth and final appearance is at <a href="https://youtu.be/-oqAU5VxFWs?t=263" rel=" noopener noreferrer" target="_blank">4:23</a> where we see <span style={{color: '#ff0201'}}>Mr. Jones</span> continuing to revel in the shadows, as if he's won.</p>
         <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/mrjones6.png" alt="mrjones6" style={{width: '475px', maxWidth: '100%'}} /></div>
        <p>If we&nbsp;had more time, we could dive into the numerological significance of the timestamps and frequency of his appearances, but for now we can just observe that <span style={{color: '#ff0201'}}>Mr. Jones</span> is always in silhouette, always out of reach, and always seemingly up to something.</p>
        </div>
      </Container>
      <Container>
        <div class="container" id="theology">
          <h2>Theology</h2>
          <p>What <em>is </em>the relationship between Mr. Jones and this father figure? If we look to the <a href="https://en.wikipedia.org/wiki/Hebrew_Bible" rel=" noopener noreferrer" target="_blank">Tanakh</a>,&nbsp;Satan is defined as being one of the sons of God who came down to Earth and procreated. From Genesis 6:1-4:</p>
          <p class="centerQuote"><em>When man began to multiply on the face of the land and daughters were born to them, the sons of God saw that the daughters of man were attractive. And they took as their wives any they chose. Then the Lord said, "My Spirit shall not abide in man forever, for he is flesh: his days shall be 120 years." The&nbsp;<a href="https://en.wikipedia.org/wiki/Nephilim" title="Nephilim">Nephilim</a>&nbsp;were on the earth in those days, and also afterward, when the sons of God came in to the daughters of man and they bore children to them. These were the mighty men who were of old, the men of renown.</em></p>
          <p>Within this relationship of Satan and God, we can see a parallel where <span style={{color: '#ff0201'}}>Mr. Jones</span> is Satan and his father is God himself. However, the God-figure in this song is a darker force, one&nbsp;that wishes to propel both <span style={{color: '#ff0201'}}>Adam</span> and the listeners into throes of madness.</p>
          <p>While it may be obvious, <span style={{color: '#ff0201'}}>Adam</span>&nbsp;plays the role of Adam, the first man as told by the Bible. And as <span style={{color: '#ff0201'}}>Adam</span> descends to the level of the devil, he signals that Original Sin is still alive and well and being reaffirmed every day.</p>
          <p>If <span style={{color: '#ff0201'}}>Adam</span> is playing the role of the original man, then he also occupies the space of Original Sin. We have already explored much of the relationship between <span style={{color: '#ff0201'}}>Adam</span> and the devil, of <span style={{color: '#ff0201'}}>Adam</span> and his dark curiosities, and of <span style={{color: '#ff0201'}}>Adam</span> and his perpetuation of sin (vanity, lust, etc.)</p>
          <p>There is so much to cover here that I must admit it would be a fool's errand to try and encapsulate it all here for you. But many scholars have written in depth about the theology in the song, so I encourage you to seek out those sources.</p>
          <p>The Counting Crows aren't the only ones spreading this sermon. We need only turn to the internet to see the evidence of others furthering <span style={{color: '#ff0201'}}>Adam</span>'s message.</p>
        </div>
      </Container>
      <Container>
        <div class="container" id="others">
          <h2>Others</h2>
          <p>Many have tried to perform this song, but those videos have been taken down from the internet without rhyme or reason. One of the few remaining renditions is a collegiate co-ed acapella group</p>
          <br />
          <div class="youtube"><iframe title="mrjones2" xml="lang" src="//www.youtube.com/embed/avuO3Sm4Hfg" allowfullscreen="allowfullscreen" data-service="youtube"></iframe></div>
          <br />
          <br />
          <p>The song heard through this filter of young vocalists gives us a different perspective on the song. Something closer to hope, to innocence, and yet still sinister. A curséd chorus working their way through <span style={{color: '#ff0201'}}>Adam</span> own madness. At the time of writing this, the video has just over 13,000 views and yet only 7 comments. One of those comments, from user okonh0wp reads: “good point <span style={{textDecoration: 'underline'}}>nrfboystore</span> and yet there is no other commenter named <span style={{textDecoration: 'underline'}}>nrfboystore</span> on the video.</p>
          <p>I thought this might be referencing a deleting comment. I searched YouTube for this user, but it returned no results. Perplexed, I searched for nrfboystore on Google—again, there were no results at all. Who is nrfboystore? And&nbsp;what were they trying to tell us?</p>
          <p>The other haunting fact is that the acapella group is from the University of Wisconsin, which is located in Madison, Wisconsin. It's no secret that the counting crows <a href="https://www.channel3000.com/news/counting-crows-concert-canceled-at-breese-stevens-field-due-to-weather-safety/797352113" rel=" noopener noreferrer" target="_blank">cancelled a show</a> in Madison in September of 2018.</p>
          <p>While it's not uncommon for a concert to be cancelled, it is important to note that the University is supposedly <a href="https://ghost.hauntedhouses.com/wisconsin_madison_science_hall_university_of_wisconsin#" rel=" noopener noreferrer" target="_blank">haunted</a>.&nbsp;This may very well be the reason that the show was cancelled. <span style={{color: '#ff0201'}}>Adam</span>, sensitive to energies from the other side, would be more vulnerable to hauntings (and therefore possessions) and thus would want to avoid the campus altogether.&nbsp;</p>
          <p>The University also published a short story entitled "Occultist Museum" in 2013. The story can be found <a href="https://minds.wisconsin.edu/bitstream/handle/1793/67812/14NMOccultMuseum.pdf?sequence=1&amp;isAllowed=y" rel=" noopener noreferrer" target="_blank">here</a> and hints at possible occult connections that <span style={{color: '#ff0201'}}>Adam</span> would want to avoid. I urge you to read this story and derive your own connections&nbsp;from its themes.</p>
          <h2><strong>A few last words</strong></h2>
          <p>So now we come to the end. By now you will have formed your own opinions on the matter, but I'm curious...do you believe this song to have hidden meanings darker than you would have first thought? Are you able to discern the coded meanings through the guise of adult-alternative? Have you been been able to reveal the ink, whether through heat or light or rational questioning, to decipher the messages on the cloth?</p>
          <p>Who is <span style={{color: '#ff0201'}}>Adam</span>? And where did all these crows come from?&nbsp;</p>
          <div class="imgContainer"><img src="https://cdn2.hubspot.net/hubfs/2272014/mjt/google-image-result-for-http---wwwgstaticcom-tv-thumb-persons-81139-81139_v9_bajpg.png" alt="adam_" style={{width: '100%'}}></img></div>
        </div>
      </Container>   
      </div>
    );
  }
}

export default MainContent;

