import React, {Component} from 'React';

class TableOfContents extends React.Component {

  render() {
    return (
      <div class="table-of-contents">
        <div class="toc nav">
          <p>Table of contents</p>
          <ul id="top-menu">
            <li class="links"><a href="#origins"> 1. Origins</a></li>
            <li class="links"><a href="#adam"> 2. Adam</a></li>
            <li class="links"><a href="#lyrics">3. Lyrics</a></li>
            <li class="links"><a href="#cryptography">4. Cryptography</a></li>
            <li class="links"><a href="#video">4. The music video</a></li>
            <li class="links"><a href="#theology">5. Theology</a></li>
            <li class="links"><a href="#others">6. Others</a></li>
            <br />
            <li id="main">
              <button class="openbtn" onClick={this.props.showSidebar}>
                Lyrics
              </button>
            </li>
          </ul>
        </div> 
      </div>
    )
  }
}

export default TableOfContents;


// function openNav() {
//     var e = document.getElementById("mySidebar");
//     if (e.style.width == '250px')
//     {
//         e.style.width = '0px';
//     }
//     else 
//     {
//         e.style.width = '250px';
//     }
// }

// function closeNav() {
//     document.getElementById("mySidebar").style.width = "0";
// }

// (document).ready(function () {
//   var sections = ('.container').(window).scroll(function () {
//     var currentScroll = (this).scrollTop().varcurrentSectionsections.each(function () {
//       var divPosition = (this).offset().top.if(divPosition - 1 < currentScroll) {
//         currentSection = (this).
//       }
//       var id = currentSection.attr('id').('a').removeClass('active').("[href=#" + id + "]").classList.add('active').
//     });
//   });
// });

// (document).ready(function () {
//   var sections = ('.container').(window).scroll(function () {
//     var currentScroll = (this).scrollTop().varcurrentSectionsections.each(function () {
//       var divPosition = (this).offset().top.if(divPosition - 1 < currentScroll) {
//         currentSection = (this).
//       }
//       var id = currentSection.attr('id').('a').removeClass('active').("[href=#" + id + "]").classList.add('active').varidToSectionTitle = {
//         origins: "1.Origins",
//         adam: '2.Adam',
//         lyrics: '3.Lyrics',
//         iconography: '4.Iconography',
//         theology: '5.Theology',
//         references: '6.Others',
//         others: '7.References'
//       }("#title").text(idToSectionTitle[id]).
//     });
//   });
// });